import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getVenueHireRoomDetailPagePreview as PREVIEW_QUERY } from "../preview-queries/venueHireRoomDetailPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import CentredContentWithSidebar from "../components/Shared/CentredContentWithSidebar"
import Content from "../components/Shared/Content"
import Article from "../components/Shared/Article"
import ShareLinks from "../components/Shared/ShareLinks"
import Sidebar from "../components/Shared/Sidebar"
import CTA from "../components/Shared/CTA"
import LargeImageBlock from "../components/Shared/LargeImageBlock"
import { venueForPage } from "../helpers/venueForPage"
import { parentRoute } from "../helpers/routingHelpers"
import VenueHireFooterCTA from "../components/Shared/VenueHireFooterCTA"
import SEOSettings from "../components/Shared/SEOSettings"

const VenueHireRoomDetailPage = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({ page: page, menus: props.data.menus })
    const superTitle = "Venue Hire"

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge
                superTitle={superTitle}
                title={page.title}
                introduction={page.page_introduction.introduction}
                image={page.image.image}
                backlink={parentRoute(props.path)}
                backtext="See all rooms"
            />

            <Main>
                <CentredContentWithSidebar>
                    <Content>
                        <Article>
                            <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                            <ShareLinks url={page.link} excerpt={page.page_introduction.introduction} />
                        </Article>
                    </Content>

                    <Sidebar>{page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}</Sidebar>
                </CentredContentWithSidebar>

                {page.room_detail_fields.roomImages && <SectionTitle>More images</SectionTitle>}

                {page.room_detail_fields.roomImages && page.room_detail_fields.roomImages.map((image) => <LargeImageBlock {...image} />)}

                {page.footer_cta_options && page.footer_cta_options.showPeople && <VenueHireFooterCTA {...page.footer_cta_options} />}
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query VenueHireRoomDetailPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            ...RoomDetailContent
            ...SidebarCtas
            ...FooterCTASettings
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(VenueHireRoomDetailPage)
