import gql from "graphql-tag"

export const getVenueHireRoomDetailPagePreview = gql`
    query getVenueHireRoomDetailPagePreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        content
                        contentType {
                            node {
                                name
                            }
                        }
                        venue {
                            venue {
                                ... on Venue {
                                    venueId: databaseId
                                    slug
                                    venue_fields {
                                        hasCustomNavigationMenu
                                        venueNavigationMenuId
                                    }
                                }
                            }
                        }
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        room_detail_fields {
                            roomImages {
                                image {
                                    sourceUrl
                                    title
                                }
                                description
                            }
                        }
                        sidebar_calls {
                            sidebarCtas {
                                ... on CTA {
                                    id
                                    title
                                    cta_fields {
                                        link {
                                            title
                                            target
                                            url
                                        }
                                        description
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        footer_cta_options {
                            showPeople
                            title
                            description
                            people {
                                ... on Person {
                                    id
                                    title
                                    person_fields {
                                        ctaDescription
                                        email: emailAddress
                                        role
                                        phone: telephoneNumber
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
