import React from "react"

// Styles
import * as styles from "./LargeImageBlock.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage";
import Image from "./Image";
import {imageSizes} from "../../helpers/imgix";

const LargeImageBlock = (props) => {

    return <div className={styles.LargeImageBlock}>

        <div>

            <div className={styles.image}>
                {props.image &&
                    <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero}/>
                }
            </div>
            
            {props.description &&
                <div className={styles.textContent}>
                    {props.description}
                </div>
            }

        </div>

    </div>

}

export default LargeImageBlock